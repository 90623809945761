<template>
  <v-container fluid>
    <base-loader :isLoading="isLoading"></base-loader>
    <base-dialog-notification ref="paymentsScheduleConfirm" />
    <base-snackbar-notification ref="paymentsScheduleSnackbar" />
    <template v-if="users.records.length > 0">
      <v-row>
        <v-col cols="12" md="6">
          <!-----------------------Start of Employee Selector ---------------------------->
          <div class="name-selecter-parent">
            <div class="name-selecter" @click="show = !show">
              <v-btn color="black" text>
                {{
                  selectedItem
                    ? selectedItem.firstName + " " + selectedItem.lastName
                    : "All"
                }}
                <v-icon :class="' ico-point2'">{{
                  show ? "mdi-menu-up" : "mdi-menu-down"
                }}</v-icon>
              </v-btn>
            </div>
            <v-expand-transition>
              <div v-show="show" class="select-list">
                <v-list-item-group color="white">
                  <v-list-item
                    v-for="(item, i) in users.records"
                    :key="i"
                    @click="
                      selectedItem = item;
                      show = !show;
                      model = item.id;
                      userDetails = item;
                      getDataForWorkTable(item.id, null, item.lastPaymentDate);
                    "
                  >
                    <v-list-item-icon>
                      <v-icon v-if="!selectedItem.profilePicture">mdi-account</v-icon>
                      <v-avatar v-else size="42">
                        <v-img :src="selectedItem.profilePicture"></v-img>
                      </v-avatar>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.firstName + ' ' + item.lastName"
                        class="text-subtitle-1"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </div>
            </v-expand-transition>
          </div>
          <!-----------------------End of Selector ---------------------------->

          <!-----------------------Start of Employee Payment lines ---------------------------->
  <!--        <v-row>-->
  <!--          <v-btn color="primary" @click="getDataForWorkTable">Get Data</v-btn>-->
  <!--        </v-row>-->
          <template v-if="wagePeriod === 'per_day' && showWorkTable">
            <v-list color="white" dense>
              <v-row>
                <v-col cols="12"><span class="text-h5">Days Worked - {{startDate}} - {{endDate}}</span></v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <v-list-item v-if="workTable.totals.rejected">
                <v-list-item-icon class="mr-3">
                  <v-icon
                      :color="returnWorkEventColour('rejected')"
                      x
                  >{{ returnWorkEventIcon('rejected') }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-capitalize font-weight-bold">Rejected</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action-text>
                  <v-list-item-title class="font-weight-black" v-text="workTable.totals.rejected"></v-list-item-title>
                </v-list-item-action-text>
              </v-list-item>
              <v-list-item v-if="workTable.totals.pending">
                <v-list-item-icon class="mr-3">
                  <v-icon
                      :color="returnWorkEventColour('pending')"
                      x
                  >{{ returnWorkEventIcon('pending') }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-capitalize font-weight-bold">Pending</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action-text>
                  <v-list-item-title class="font-weight-black" v-text="workTable.totals.pending"></v-list-item-title>
                </v-list-item-action-text>
              </v-list-item>
              <v-list-item v-if="workTable.totals.approved && wageTracking !== 'automatic'">
                <v-list-item-icon class="mr-3">
                  <v-icon
                      :color="returnWorkEventColour('approved')"
                  >{{ returnWorkEventIcon('approved') }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">Approved ({{returnCurrencyFormattedNumber(workTable.wageRate, workTable.currencyCode)}}/day)</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action-text>
                  <v-list-item-title class="font-weight-black" v-text="workTable.totals.approved"></v-list-item-title>
                </v-list-item-action-text>
              </v-list-item>
              <v-list-item v-if="workTable.totals.approved && wageTracking === 'automatic'">
                <v-list-item-icon class="mr-3">
                  <v-icon color="secondary">mdi-calendar-check</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">Worked Days ({{returnCurrencyFormattedNumber(workTable.wageRate, workTable.currencyCode)}}/day)</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action-text>
                  <v-list-item-title class="font-weight-black" v-text="workTable.totals.approved"></v-list-item-title>
                </v-list-item-action-text>
              </v-list-item>
              <v-list-item v-if="workTable.totals.total === 0">
                <v-list-item-icon class="mr-3">
                  <v-icon color="grey darken-1">mdi-calendar-month</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-capitalize font-weight-bold">None</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider class="my-2"></v-divider>
            <v-row>
              <v-col cols="6"><v-spacer></v-spacer></v-col>
              <v-col cols="6" class="text-right"><span class="text-h5 font-weight-black mr-2">TOTAL:</span><span class="mr-2 text-h5 font-weight-black">{{ returnCurrencyFormattedNumber(workTable.totalWorkPay, workTable.currencyCode) }}</span></v-col>
            </v-row>
          </template>
          <template v-if="wagePeriod === 'per_month' && showSalaryTable">
            <v-row>
              <v-col cols="12"><span class="text-h5">Salary for: {{startDate}} - {{endDate}}</span></v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row>
              <v-col cols="6"><v-spacer></v-spacer></v-col>
              <v-col cols="6" class="text-right"><span class="text-h5 font-weight-black mr-2">TOTAL:</span><span class="mr-2 text-h5 font-weight-black">{{ returnCurrencyFormattedNumber(workTable.totalWorkPay, workTable.currencyCode) }}</span></v-col>
            </v-row>
          </template>
          <template v-if="showDeductions">
            <v-row>
              <v-col cols="4"><span class="text-h5">Deductions</span></v-col>
              <v-col cols="4"><v-spacer></v-spacer></v-col>
              <v-col cols="4" class="text-right py-0 my-auto">
                <v-btn
                  class="mr-4"
                  fab
                  dark
                  x-small
                  elevation="0"
                  color="primary"
                  @click="deductions.push($_.cloneDeep(deductionObj))"
                  v-show="!hasNewDeductions"
                >
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn
                    class="mr-4"
                    fab
                    dark
                    x-small
                    elevation="0"
                    color="warning"
                    @click="deductions.splice(-1, 1)"
                    v-show="hasNewDeductions"
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row v-if="deductions.length > 0" class="mb-0">
              <v-col cols="12" class="ma-0 pa-0">
                <v-list color="white" dense class="mt-2">
                  <v-list-item v-for="(deduction, index) in deductions" :key="index">
                    <v-list-item-icon class="mr-3" v-if="!deduction.new">
                      <v-icon color="warning">mdi-cash-remove</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content v-if="!deduction.new">
                      <v-list-item-title class="text-capitalize font-weight-bold mr-3" v-text="deduction.description"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content v-else>
                      <v-text-field
                        v-model="deduction.description"
                        dense
                        outlined
                        hide-details
                        maxlength="500"
                        placeholder="Description"
                        class="mr-2 text-body-2"
                      ></v-text-field>
                    </v-list-item-content>
                    <v-list-item-action-text v-if="!deduction.new">
                      <v-list-item-title class="font-weight-black">{{ returnCurrencyFormattedNumber(deduction.amount, currencyCode) }}</v-list-item-title>
                    </v-list-item-action-text>
                    <v-list-item-action-text v-else tag="div">
                      <v-text-field
                          v-model="deduction.amount"
                          dense
                          outlined
                          hide-details
                          placeholder="Amount"
                          prefix="R"
                          type="tel"
                          class="deduction-amount-text-field text-body-2"
                      ></v-text-field>
                    </v-list-item-action-text>
                    <v-list-item-action v-if="!deduction.new">
                      <v-btn
                        icon
                        x-small
                        elevation="0"
                        color="warning"
                        @click="deductions.splice(index, 1)"
                      ><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                    </v-list-item-action>
                    <v-list-item-action v-else>
                      <v-btn
                          icon
                          x-small
                          elevation="0"
                          color="secondary"
                          @click="deduction.new = false"
                      ><v-icon>mdi-check</v-icon></v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12"><span class="text-body-2">No deductions</span></v-col>
            </v-row>
            <template v-if="totalDeductions !== 0">
              <v-divider class="my-2"></v-divider>
              <v-row>
                <v-col cols="6"><v-spacer></v-spacer></v-col>
                <v-col cols="6" class="text-right"><span class="text-h5 font-weight-black mr-2">TOTAL:</span><span class="mr-2 text-h5 font-weight-black">- {{ returnCurrencyFormattedNumber(totalDeductions, workTable.currencyCode) }}</span></v-col>
              </v-row>
            </template>
            <template v-else>
              <v-row  class="my-4">
              </v-row>
            </template>
          </template>
          <template v-if="showServiceFees">
            <v-row class="mt-6">
              <v-col cols="6" class="py-0 my-0"><span class="text-body-1 font-weight-medium">{{serviceFeeWording}}</span></v-col>
              <v-col cols="2" class="py-0 my-0"><v-spacer></v-spacer></v-col>
              <v-col cols="4" class="py-0 my-0 text-right"><span class="mr-2 text-body-1 font-weight-medium">+ {{ returnCurrencyFormattedNumber(totalServiceFee, currencyCode) }}</span></v-col>
            </v-row>
          </template>
          <template v-if="showPayable">
            <v-row class="mt-1">
              <v-col cols="6"><span class="text-h5 font-weight-black">TOTAL PAYMENT</span></v-col>
              <v-col cols="6" class="text-right"><span class="mr-2 text-h5 font-weight-black">{{ returnCurrencyFormattedNumber(totalPayment, currencyCode) }}</span></v-col>
            </v-row>
            <v-divider class="my-2"></v-divider>
          </template>
          <template v-if="showPayButtons && userBankingDetailsExist">
            <v-row class="mt-4">
              <v-col cols="12" class="d-flex mx-auto">
                <v-spacer></v-spacer>
                <v-btn
                    elevation="0"
                    color="secondary"
                    class="black--text"
                    :loading="payButtonLoading"
                    :disabled="payButtonLoading"
                    @click="savePayment">Pay {{ returnCurrencyFormattedNumber(totalPayment, currencyCode) }}</v-btn>
                <v-spacer></v-spacer>
              </v-col>
            </v-row>
          </template>
          <template v-if="!userBankingDetailsExist">
            <v-row class="mt-4">
              <v-col cols="12" class="d-flex mx-auto">
                <v-alert
                    dense
                    outlined
                    type="warning"
                >There are no banking details saved for this employee. Please update the employee's banking details to be able to make a payment.</v-alert>
              </v-col>
            </v-row>
          </template>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="absolute-center"
          v-if="
            $vuetify.breakpoint.md ||
            $vuetify.breakpoint.lg ||
            $vuetify.breakpoint.xl
          "
        >
          <v-avatar class="" size="400" tile>
            <v-img src="../../assets/FairwageLogo.png"></v-img>
          </v-avatar>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row class="mt-4">
        <v-col cols="12" class="d-flex mx-auto">
          <v-alert
              dense
              outlined
              type="warning"
          >You don't have any employees setup. Please add an employee first.</v-alert>
        </v-col>
      </v-row>
    </template>
    <template>
      <v-row justify="center">
        <v-dialog
            v-model="showProcessPayment"
            persistent
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
          <v-toolbar dark color="primary">
            <v-btn
                icon
                dark
                @click="showProcessPayment = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title class="white--text">Credit Card Payment</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <base-payment-process
              ref="paymentProcessForm"
              :employeeFullName="userDetails.firstName + ' ' + userDetails.lastName"
              :employeeId="selectedItem.id"
              :totalPayment="totalPayment"
              :paymentCurrencyCode="currencyCode"
              :paymentCurrencyString="currencyString"
              :paymentId="paymentId"
              :paymentRef="employerPaymentReference"
          />
        </v-dialog>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { gql } from "apollo-boost";
import Holidays from "date-holidays";

export default {
  components: {},
  name: "Upcoming Payments",
  async mounted() {
    this.$emit("update:pageName", this.$options.name);
    this.userContracts = this.$route.params.userContracts
    this.showWorkTable = false
  },
  data() {
    return {
      isLoading: false,
      payButtonLoading: false,
      showWorkTable: false,
      showSalaryTable: false,
      wagePeriod: null,
      wageTracking: null,
      wageRate: null,
      workTable: null,
      startDateRaw: null,
      endDateRaw: null,
      showDeductions: true,
      deductionObj: {
        description: "",
        amount: null,
        new: true,
      },
      deductions: [],
      showPayable: true,
      showServiceFees: true,
      currencyCode: "ZAR",
      currencyString: "R",
      totalWorkPay: 0,
      paymentId: null,
      employerPaymentReference: null,
      configSettings: {},
      showProcessPayment: false,
      show: false,
      showMore: false,
      routeEmployeeId: null,
      routePaymentId: this.$route.params.id || 'all',
      selectedItem: 1,
      userDetails: {},
      model: "tab-1",
      users: [],
      userBankingDetailsExist: false,
      payments: [],
      paymentsData: [],
      page: 1,
      id: "",
      payDay: null,
      currentPayment: {},
      UserId: "",
      tab: null,
      newItems: [],
      dialog: false,
      hd: new Holidays("ZA"),
    };
  },
  apollo: {
    me: gql`
      query {
        me {
          id
          firstName
          lastName
        }
      }
    `,
    configValues: {
      query: gql`
        query GetConfigValues {
          configValues {
            records
          }
        }
      `,
      fetchPolicy: "no-cache",
      result(response, key) {
        this.configSettings = this.$_.get(response, 'data.configValues.records', {})
      }
    },
    users: {
      query: gql`
        query Users($limit: Int, $page: Int, $query: JSON!) {
          users(limit: $limit, page: $page, query: $query) {
            records {
              id
              firstName
              lastName
              role
              bankName
              branchCode
              accountNumber
              accountType
              accountHolder
              paymentSetupType
              lastPaymentDate
            }
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        const limit = 9999;
        return {
          limit: limit,
          page: this.page,
          query: { employers: { $in: [this.me.id] }, role: "employee", deleted: { $ne: true} },
        };
      },
      result({ data }, key) {
        this.newItems = data.users.records;
        if (this.newItems.length > 0) {
          this.selectedItem = this.newItems[0];
          this.userId = this.newItems[0].id;
          this.userDetails = this.newItems[0];
        }
        if (this.routeEmployeeId !== null) {
          const employee = this.newItems.find(item => item.id === this.routeEmployeeId)
          this.userId = this.routeEmployeeId
          this.userDetails = employee
          this.selectedItem = employee
          this.getDataForWorkTable(this.userId, null, this.userDetails.lastPaymentDate)
        } else if (this.userId !== "") {
          if (this.routePaymentId === 'all') {
            this.getDataForWorkTable(this.userId, null, this.userDetails.lastPaymentDate)
          } else {
            this.getPaymentById(this.routePaymentId).then((data) => {
              this.$set(this, 'currentPayment', data)
              this.getDataForWorkTableFromPayment(this.currentPayment.employeeId, this.currentPayment.workDaysStartDate, this.userDetails.workDaysEndDate, this.currentPayment)
            })
          }
        }
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
  },
  methods: {
    async getDataForWorkTable (id, currentDate = null, lastPaymentDate = null) {
      this.isLoading = true
      this.employerPaymentReference = null
      this.paymentId = null
      if (!currentDate) {
        currentDate = this.$moment().format('x')
      }
      let userContract = null
      if (this.$_.isEmpty(this.userContracts)) {
        if (id) {
          userContract = await this.getContractByUserId(id).catch((err) => { return err })
        } else {
          userContract = {}
          this.isLoading = false
          return null
        }
      }else{
        userContract = this.$_.find(this.userContracts, { userId: id })
      }
      this.userBankingDetailsExist = this.checkBankingDetailsExist(this.userDetails)
      this.wagePeriod = userContract.wagePeriod
      this.wageTracking = userContract.wageTracking
      this.deductions = []
      this.getEvents(id, currentDate, lastPaymentDate).then((data) => {
        this.$set(this, 'workTable', data)
        this.currencyCode = data.currencyCode
        this.currencyString = data.currencyString
        this.totalWorkPay = data.totalWorkPay
        this.wageRate = data.wageRate
        this.startDateRaw = data.startDateInt
        this.endDateRaw = data.endDateInt
        this.isLoading = false
        if (this.$_.size(this.workTable.totals) > 0) {
          this.showWorkTable = true
          this.showSalaryTable = false
        } else {
          this.showWorkTable = false
          this.showSalaryTable = true
        }
      }).catch((err) => {
        console.error('err', err)
        this.isLoading = false
      })
    },
    async getDataForWorkTableFromPayment (id, currentDate = null, lastPaymentDate = null, payment) {
      this.isLoading = true
      if (!currentDate) {
        currentDate = this.$moment().format('x')
      }
      let userContract = null
      if (this.$_.isEmpty(this.userContracts)) {
        userContract = await this.getContractByUserId(id).catch((err) => { return err })
      }else{
        userContract = this.$_.find(this.userContracts, { userId: id })
      }
      this.paymentId = payment.id
      this.employerPaymentReference = payment.employerPaymentReference
      this.wagePeriod = payment.wagePeriod
      this.wageTracking = payment.wageTracking
      this.deductions = payment.deductions
      this.currencyCode = payment.currencyCode
      this.currencyString = payment.currencyString
      this.totalWorkPay = payment.totalWorkPay
      this.wageRate = payment.wageRate
      this.getEvents(id, currentDate, lastPaymentDate).then((data) => {
        this.$set(this, 'workTable', data)
        this.startDateRaw = data.startDateInt
        this.endDateRaw = data.endDateInt
        this.isLoading = false
        if (this.$_.size(this.workTable.totals) > 0) {
          this.showWorkTable = true
          this.showSalaryTable = false
        } else {
          this.showWorkTable = false
          this.showSalaryTable = true
        }
      }).catch((err) => {
        console.error('err', err)
        this.isLoading = false
      })
    },
    async getEvents(userId, currentDate, lastPaymentDate) {
      return new Promise(async (resolve, reject) => {
        if(!userId) return
        if(!currentDate) return
        this.$apollo.query({
          query: gql`
            query GetWorkEvents($userId: ID!, $currentDate: Float!, $lastPaymentDate: Float) {
              paymentWorkEvents(userId: $userId, currentDate: $currentDate, lastPaymentDate: $lastPaymentDate) {
                records
              }
            }
          `,
          fetchPolicy: 'no-cache',
          variables: {
            userId: userId,
            currentDate: this.$_.toNumber(currentDate),
            lastPaymentDate: this.$_.toNumber(lastPaymentDate),
          },
        }).then((data) => {
          const workEvents = _.get(data, 'data.paymentWorkEvents.records', [])
          return resolve(workEvents)
        }).catch((err) => {
          console.error('Error:', err)
          this.$refs.paymentsScheduleConfirm.open("Error", "There was a problem while loading the Employees data. Please try again or contact support.", "error")
          return resolve([])
        })
      })
    },
    returnWorkEventIcon(name) {
      if (name === 'processed') {
        return 'mdi-cash-check'
      }
      if (name === 'approved') {
        return 'mdi-check'
      }
      if (name === 'rejected') {
        return 'mdi-close'
      }
      if (name === 'pending') {
        return 'mdi-progress-check'
      }
      return ''
    },
    returnWorkEventColour(name) {
      if (name === 'processed') {
        return 'blue-grey darken-1'
      }
      if (name === 'approved') {
        return 'secondary'
      }
      if (name === 'rejected') {
        return 'red darken-4'
      }
      if (name === 'pending') {
        return 'amber lighten-3'
      }
      return 'secondary'
    },
    returnCurrencyFormattedNumber (number, currency) {
      if (number == null || number === 'undefined') return 'R 0.00'
      if (!currency) return `R ${number}`
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'narrowSymbol'
      })
      return formatter.format(number)
    },
    async getContractByUserId (userId) {
      return new Promise(async (resolve, reject) => {
        this.$apollo.query({
          query: gql`
            query GetContract($userId: ID!) {
              contractByUserId(userId: $userId) {
                id
                userId
                jobTitle
                duties
                contractCurrency
                terminationNoticePeriod
                wagePaymentSchedule
                wagePaymentDate
                wagePaymentWeekDay
                wagePeriod
                wagePeriodUnitType
                wageTracking
                wageRate
                overtimeAvailable
                overtimeRate
                publicHolidayRate
                hoursOfWorkStart
                hoursOfWorkEnd
                lunchPeriod
                createdAt
                updatedAt
              }
            }
          `,
          fetchPolicy: "no-cache",
          variables: {
            userId: userId
          }
        }).then((data) => {
          if (_.has(data, 'data.contractByUserId.id')) {
            return resolve(data.data.contractByUserId)
          } else {
            return reject('No data found')
          }
        }).catch((error) => {
          console.error('Error:', error)
          return reject(error)
        })
      })
    },
    async getPaymentById(id) {
      return new Promise(async (resolve, reject) => {
        this.$apollo.query({
          query: gql`
            query GetPayment($id: ID!) {
              payment(id: $id) {
                id
                employerId
                employerFirstName
                employerLastName
                employerPaymentDate
                employerPaymentAmount
                employerPaymentReference
                employerPaymentAccepted
                employerPaymentFailureReason
                employerPaymentRequestTrace
                employerPaymentMethod
                employerPaymentCcToken
                employerPaymentCcHolder
                employerPaymentCcMasked
                employerPaymentCcExpiry
                employeeId
                employeeFirstName
                employeeLastName
                employeeAccountNumber
                employeeAccountType
                employeeAccountHolder
                employeeBankName
                employeeBranchCode
                employeePaymentDate
                employeePaymentAmount
                totalWorkPay
                wageRate
                wagePeriod
                wageTracking
                workDaysStartDate
                workDaysEndDate
                currencyCode
                deductions {
                  id
                  description
                  amount
                }
                serviceFeeAmount
                serviceFeeType
                createdAt
                createdBy
                updatedAt
                updatedBy
                deleted
                deletedAt
                deletedBy
              }
            }
          `,
          fetchPolicy: "no-cache",
          variables: {
            id: id
          }
        }).then((data) => {
          if (_.has(data, 'data.payment.id')) {
            return resolve(data.data.payment)
          } else {
            return reject('No data found')
          }
        }).catch((error) => {
          console.error('Error:', error)
          return reject(error)
        })
      })
    },
    async savePayment () {
      return new Promise(async (resolve, reject) => {
        this.payButtonLoading = true
        this.paymentId = null
        this.employerPaymentReference = null
        const payment = {
          employerId: this.me.id,
          employerFirstName: this.me.firstName,
          employerLastName: this.me.lastName,
          employerPaymentDate: new Date().getTime(),
          employerPaymentAmount: this.totalPayment,
          employeeId: this.userDetails.id,
          employeeFirstName: this.userDetails.firstName,
          employeeLastName: this.userDetails.lastName,
          employeeAccountNumber: this.userDetails.accountNumber,
          employeeAccountType: this.userDetails.accountType,
          employeeAccountHolder: this.userDetails.accountHolder,
          employeeBankName: this.userDetails.bankName,
          employeeBranchCode: this.userDetails.branchCode,
          employeeRequestedPaymentDate: new Date().getTime(),
          employeePaymentDate: new Date().getTime(),
          employeePaymentAmount: this.totalPay,
          totalWorkPay: this.totalWorkPay,
          wageRate: this.wageRate,
          wagePeriod: this.wagePeriod,
          wageTracking: this.wageTracking,
          workDaysStartDate: this.$_.toNumber(this.$moment(this.startDateRaw).format('x')),
          workDaysEndDate: this.$_.toNumber(this.$moment(this.endDateRaw).format('x')),
          currencyCode: this.currencyCode,
          deductions: this.cleanDeductionsArray(this.deductions),
          serviceFeeAmount: this.configSettings.payments_service_fee,
          serviceFeeType: this.configSettings.payments_service_type,
          createdAt: new Date().getTime(),
          createdBy: this.me.id
        }

        this.$apollo.mutate({
          mutation: gql`
              mutation CreatePayment($payment: PaymentCreateInput!) {
                createPayment(payment: $payment) {
                  id
                  employerPaymentReference
                }
              }
            `,
          fetchPolicy: "no-cache",
          variables: {
            payment: payment
          }
        }).then((data) => {
          if(this.$_.has(data, 'data.createPayment.id')) {
            this.paymentId = data.data.createPayment.id
            this.employerPaymentReference = data.data.createPayment.employerPaymentReference
          }
          this.payButtonLoading = false
          this.showProcessPayment = true
          return resolve(true)
        }).catch((error) => {
          this.payButtonLoading = false
          console.error('Create Payment Mutation Error:', error)
          this.$refs.paymentsScheduleConfirm.open("Error", "There was a problem while saving the payment details. Please try again or contact support.", "error")
          return reject(error)
        })
      })
    },
    cleanDeductionsArray(deductions) {
      return this.$_.map(deductions, (deduction, index) => {
        return {
          id: index,
          description: deduction.description,
          amount: this.$_.toNumber(deduction.amount),
        }
      })
    },
    /**
     * Currency 2 decimal place rounding, safe for getting service fees accurate.
     * @param n - Number to round
     * @param digits - Decimal places to round to.
     * @returns {float}
     */
    currencyRoundTo(n, digits) {
      if (digits === undefined) digits = 0
      const multiplicator = Math.pow(10, digits)
      n = parseFloat((n * multiplicator).toFixed(11))
      return this.$_.toNumber((Math.round(n) / multiplicator).toFixed(2))
    },
    checkBankingDetails(user) {
      if (this.checkBankingDetailsExist(user)) {
        return true
      } else {
        this.$refs.paymentsScheduleConfirm.open("Banking Details Required", "Please enter your banking details before you can schedule a payment.", "warning")
        return false
      }
    },
    checkBankingDetailsExist(user) {
      if (this.$_.has(user, 'accountNumber') && this.$_.has(user, 'accountHolder') && this.$_.has(user, 'bankName') && this.$_.has(user, 'branchCode')) {
        return user.accountNumber.length > 0 && user.accountHolder.length > 0 && user.bankName.length > 0 && user.branchCode.length > 0
      } else {
        return false
      }
    },
  },
  computed: {
    totalPay () {
      let totalPay = this.totalWorkPay
      if(this.$_.size(this.deductions) > 0) {
        this.$_.forEach(this.deductions, (deduction) => {
          if (!deduction.new) {
            totalPay -= deduction.amount
          }
        })
      }
      return totalPay
    },
    totalDeductions () {
      let totalDeductionsAmount = 0
      if(this.$_.size(this.deductions) > 0) {
        this.$_.forEach(this.deductions, (deduction) => {
          if (!deduction.new) {
            totalDeductionsAmount += this.$_.toNumber(deduction.amount)
          }
        })
      }
      return totalDeductionsAmount
    },
    totalServiceFee () {
      const serviceFee = this.configSettings.payments_service_fee
      const serviceType = this.configSettings.payments_service_type
      if (serviceType === 'percentage') {
        const calcServiceFee = this.totalPay * (serviceFee / 100)
        return this.$_.round(calcServiceFee, 2)
      } else {
        return serviceFee
      }
    },
    serviceFeeWording () {
      const serviceFee = this.configSettings.payments_service_fee
      const serviceType = this.configSettings.payments_service_type
      if (serviceType === 'percentage') {
        return `Service Fee (${serviceFee}%)`
      } else {
        return 'Service Fee'
      }
    },
    totalPayment () {
      const final = this.$_.round(this.totalPay + this.totalServiceFee, 2)
      return final
    },
    startDate () {
      return this.$moment(this.startDateRaw).format('DD MMM')
    },
    endDate () {
      return this.$moment(this.endDateRaw).format('DD MMM')
    },
    showPayButtons () {
      const showPayButtons = (this.totalPay > 0 )
      return showPayButtons
    },
    hasNewDeductions () {
      let hasNewDeductions = false
      if(this.$_.size(this.deductions) > 0) {
        this.$_.forEach(this.deductions, (deduction) => {
          if (deduction.new) {
            hasNewDeductions = true
          }
        })
      }
      return hasNewDeductions
    },

  },
};
</script>

<style scoped>
.herso {
  background: url("../../assets/landing.png") no-repeat center center fixed !important;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

.v-sheet.v-card {
  cbackground: #6dd984;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.theme--light.v-list {
  background: #6dd984;
}

.card-header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
}

.select-card {
  padding: 10px 30px;
  min-height: 60px;
  position: relative;
}

.top-sect .v-btn {
  width: calc(100% - 67px);
  min-height: 70px;
}

.top-sect .v-btn__content {
  color: #eeecf1 !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  letter-spacing: 0.75px;
}

.top-sect .v-card__actions {
  position: absolute;
  right: 10px;
  top: 10px;
}

.top-sect .v-icon.v-icon {
  color: #ffffff;
}

.select-list {
  margin-top: 10px;
}

.ico-point {
  font-size: 30px;
}

.app-btn {
  height: auto;
  min-height: 10px;
  background-color: #fff;
  min-width: 0;
  border-radius: 12px;
  padding: 0;
}

.app-btn .v-btn__content {
  display: block !important;
  padding: 10px;
}

.v-application .secondary.app-btn {
  background-color: #f1f3f6 !important;
  border-color: #f1f3f6 !important;
  color: #3f3d56;
  box-shadow: none;
}

.v-application .primary.app-btn {
  background-color: #6dd984 !important;
  border-color: #6dd984 !important;
  color: #fff;
}

.app-btn .v-btn__content i {
  margin-bottom: 10px;
  border: 2px solid;
  border-radius: 50%;
}

.theCaleSect,
.btn-group {
  text-align: center;
}

/*/////////////////////////*/

.createNewDeduction {
  position: relative;

  right: 22px;
  top: 16px;
}

.forCale {
  background-color: white;
  padding: 10px !important;
  overflow: hidden;
}

.forCale .names {
  background: #a5a6f6;
  border-radius: 10px;
}

.v-btn:not(.v-btn--round).v-size--default.app-btn {
  height: auto;

  min-width: auto;
  padding: 10px 10px !important;
}

/*/////////////////////////*/

.name-selecter-parent {
  border: 4px solid#6dd984;
  margin-bottom: 30px;
  border-radius: 10px;
  background: #6dd984;
}

.name-selecter > button {
  width: 100%;
  position: relative;
}

.name-selecter > button i {
  position: absolute;
  right: 0;
}

/*history*/
.app-tabs-rows {
  padding: 0 30px;
}

.app-tabs-rows h1 {
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;

  color: #7d72f5;
}

.h6 {
  color: #6dd984;
}

.app-tabs-rows .col {
  padding: 20px 20px;
  margin-top: 10px;
  height: auto;
}

.app-tabs-rows p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
  color: #3f3d56;
}

.app-tabs-rows b {
  font-weight: 700;
}

.deduction-amount-text-field {
  width: 80px;
}
</style>
